
































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import { AuthStoreActions, AUTH_STORE_NAME } from "@/store/auth.store";
import {ROUTE_LOGIN} from "@/router/routes";
import {StoreActions} from "@/store";
import {passwordCriteria} from "@/misc/PasswortCriterias";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    password: {
      required,
    },
  },
})
export default class PasswordView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.RESET_PASSWORD)
  private resetPasswordAction!: (payload: {
    token: string,
    password: string
  }) => Promise<any>;

  /**
   * criterias that needs to fullfilled in order to set the password
   * @private
   */
  private passwordCriteria = [...passwordCriteria];

  /**
   * flag that indicates if the password should be hidden
   * @private
   */
  private hidePassword = true;

  /**
   * Reset Token fetched by the URL Query
   * @private
   */
  private token?: string;

  /**
   * Alert Message to Show
   * @private
   */
  private alertMessage: string = "";

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  /**
   * Password Inputs
   * @private
   */
  private password: string | null = null;

  /**
   * Loading State Bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Form Locked State Bool
   * @private
   */
  private isLocked: boolean = false;

  /**
   * Created Hook
   * Fetches the Reset Token from the URL Query
   */
  async created() {
    // Reset all stores:
    await this.$store.dispatch(StoreActions.CLEAR_ALL_STORES);

    // try to get token from query param
    const token = this.$route.query.token;

    // Token is not available through a query param
    // show an error describing that issue
    if (!token) {
      this.isLocked = true;
      this.alertMessage = this.$t("PASSWORD.INVALID_RESET_TOKEN").toString();
      this.showAlert = true;
      return;
    }

    // Parse given token as String
    this.token = token as string;
  }

  /**
   * Validate the password input regarding the password criteria an base criteria (e. g. required)
   * @param value
   */
  public validatePasswordInput(value: string) {
    // base criteria
    this.triggerValidation('password');
    // special password criteria
    this.passwordCriteria.forEach((criterion) => criterion.fullfilled = criterion.regEx.test(value));
  }

  /**
   * checks if every password criteria was checked
   * @private
   */
  private get criteriaChecked() {
    return this.passwordCriteria?.filter((c) => !c.fullfilled).length > 0;
  }

  /**
   * Reset Password Method
   * This will Reset the Password via an API call
   * @private
   */
  private async resetPassword() {
    // Disable Alert Message
    this.showAlert = false;

    // Trigger validation
    this.$v.$touch();

    // Return if invalid or is still Loading
    if (this.$v.$invalid || this.isLoading) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    if(this.criteriaChecked) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    // Try to set the new Password
    try {
      // set Loading to be active
      this.isLoading = true;

      // Reset Password via an API call
      await this.resetPasswordAction({
        token: this.token!,
        password: this.password!,
      });

      // Show a success message
      await this.$notifySuccessSimplified("PASSWORD.FINISHED");

      // Push to the login View
      await this.$router.push({name: ROUTE_LOGIN });
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          // Token Expired
          case 403:
            this.isLocked = true;
            this.alertMessage = this.$t("PASSWORD.INVALID_RESET_TOKEN").toString();
            break;
          // No Account or Token Found
          case 404:
            this.alertMessage = this.$t("PASSWORD.ACCOUNT_OR_TOKEN_NOT_FOUND").toString();
            break;
          default:
            this.alertMessage = this.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TEXT").toString();
        }

        // Show Alert Message
        this.showAlert = true;
      });
    } finally {
      this.isLoading = false;
    }
  }
}
